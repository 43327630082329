import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Drawer,
  Grid,
  Modal,
  Paper,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  delAutoFillValues,
  delJobPostProducts,
  delProduct,
  IProduct
} from 'redux/slices/productsReducer';
import useLocales from 'hooks/useLocales';
import ProductsModalCardContainer from './containers/ProductsModalContainer';
import VonqPreOrderForm from './VonqPreOrderForm';
import { Close } from '@material-ui/icons';
import { styled } from '@mui/material';
import { Title1, Title2 } from './text/title';
import NoDataImage from './images/NoData';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SvgIconStyle from './SvgIconStyle';

const DRAWER_WIDTH = '30%';

const ButtonDelete = styled(Button)(() => ({
  marginLeft: 'auto',
  span: {
    height: '100%'
  },
  img: {
    height: '100%'
  }
}));

const ModalGrid = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  zIndex: 10,
  position: 'relative'
}));

const ModalBox = styled(Box)(() => ({
  width: '590px',
  height: '300px',
  background: '#FFFFFF',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  margin: 'auto',
  alignItems: 'center',
  display: 'flex',
  position: 'relative'
}));

const ModalBoxContent = styled(Box)(() => ({
  maxWidth: '430px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  color: '#131417',
  fontFamily: 'Rubik',
  rowGap: '30px'
}));

const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  columnGap: '10px'
}));

const ModalButton = styled(Button)(() => ({
  borderRadius: '30px',
  padding: '10px 0',
  width: '130px'
}));

interface IProductPrice {
  vonq_price: Array<{ amount: number; currency: string }>;
}

const Item = ({ product }: any) => {
  const { t } = useLocales();
  return (
    <Box sx={{ my: 1.5, textTransform: 'capitalize', marginBottom: '40px' }}>
      <Grid
        container
        spacing={2}
        style={{
          justifyContent: 'space-between'
        }}
      >
        <Grid item xs={3} sx={{ padding: '5px 0 0 20px !important' }}>
          <CardMedia
            component="img"
            width="100%"
            image={
              product.logo_square_url ? product.logo_square_url[0]?.url : ''
            }
            alt="product logo"
          />
        </Grid>
        <Grid
          xs={8}
          style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}
        >
          <Grid container>
            <Grid xs={9}>
              <Typography
                display="block"
                variant="h5"
                sx={{
                  fontWeight: '600 !important',
                  p: 0,
                  fontSize: '20px !important'
                }}
              >
                {product.title}
              </Typography>
            </Grid>
            <Grid
              xs={3}
              sx={{
                textAlign: 'end'
              }}
            >
              <Close
                onClick={() =>
                  delProduct(product.product_id, product.jobPostFor.id)
                }
                style={{
                  marginLeft: 'auto',
                  cursor: 'pointer',
                  color: '#562C82',
                  fontSize: '24px'
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
                {product.channel?.type}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  textAlign: 'end',
                  color: '#666666',
                  fontWeight: 400,
                  fontSize: '16px'
                }}
              >
                {`${product.duration.period} ${product.duration.range}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  textAlign: 'end',
                  color: '#666666',
                  fontWeight: 400,
                  fontSize: '16px'
                }}
              >
                {`${product.time_to_process?.period} ${product.time_to_process?.range}`}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 1
            }}
          >
            <Typography
              sx={{
                fontWeight: '600 !important',
                fontSize: '20px !important'
              }}
            >
              {`${product.vonq_price[0].currency} ${product.vonq_price[0].amount}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const JopPostGroup = ({
  products,
  openPreOrderModal,
  handleDeleteProductsForJobPost
}: {
  products: Array<IProduct>;
  openPreOrderModal: () => void;
  handleDeleteProductsForJobPost: () => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useLocales();
  const title = products[0].jobPostFor?.title;

  const getTotalPrice = (products: Array<IProductPrice>) =>
    products
      .map((product) => product.vonq_price[0].amount)
      .reduce((prevVal, currVal) => prevVal + currVal);

  return (
    <Paper>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ModalGrid>
          <ModalBox>
            <SvgIconStyle
              onClick={() => setShowModal(false)}
              src="/static/img/icons/controls/close.svg"
              style={{
                top: '10px',
                width: '20px',
                height: '20px',
                position: 'absolute',
                right: '10px',
                cursor: 'pointer',
                filter:
                  'invert(45%) sepia(2%) saturate(20%) hue-rotate(189deg) brightness(71%) contrast(90%)'
              }}
            />
            <ModalBoxContent>
              <Title1>
                Are you sure you want to delete all Job posts from basket?
              </Title1>
              <ButtonBox>
                <ModalButton
                  variant="outlined"
                  onClick={() => setShowModal(false)}
                >
                  Back
                </ModalButton>
                <ModalButton
                  variant="contained"
                  onClick={() => {
                    setShowModal(false);
                    handleDeleteProductsForJobPost();
                  }}
                >
                  Yes
                </ModalButton>
              </ButtonBox>
            </ModalBoxContent>
          </ModalBox>
        </ModalGrid>
      </Modal>
      <Card
        style={{
          borderRadius: 0,
          boxShadow: 'none'
        }}
      >
        <CardContent>
          <Typography variant="h5">
            {t('Job post for')} - {title}
          </Typography>
          <Divider sx={{ marginBottom: '40px' }} />
          {products.map((product) => (
            <Box key={`${product.product_id}-${product.jobPostFor?.id}`}>
              <Item product={product} />
            </Box>
          ))}
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item md={4} sm={12}>
              <Typography sx={{ pr: 1 }}>
                {t('Total amount')} {`${t('EUR')} ${getTotalPrice(products)}`}
              </Typography>
            </Grid>
            <Grid
              container
              md={8}
              sm={12}
              style={{ display: 'flex', flexWrap: 'nowrap' }}
            >
              <ButtonDelete
                sx={{ p: 0.7, mr: 1, maxHeight: '40px' }}
                variant="outlined"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <SvgIconStyle src="/static/img/icons/delete.svg" height="inherit" />
              </ButtonDelete>
              <ButtonPrimary onClick={openPreOrderModal}>
                {t('Order campaign')}
              </ButtonPrimary>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

interface IProductsBasket {
  buttonTitle?: string;
}

const ProductsBasket = ({ buttonTitle }: IProductsBasket) => {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [preOrderModalOpen, setPreOrderModalOpen] = useState(false);
  const [preOrderModalData, setPreOrderModalData] = useState<Array<IProduct>>(
    []
  );

  const onOpenPreOrderModal = (data: Array<IProduct>) => {
    setPreOrderModalOpen(true);
    setPreOrderModalData(data);
  };

  const onClosePreOrderModal = () => {
    setPreOrderModalOpen(false);
    setPreOrderModalData([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const products = useSelector(
    (state: RootState) => state.productsReducer?.products
  );

  const sortProductsByJobPosts = (
    productsList: Array<IProduct>
  ): Array<Array<IProduct>> => {
    const res: Array<Array<IProduct>> = [];

    const makeArrDepth = (tempId: string | undefined) =>
      productsList.filter((el: IProduct) => el.jobPostFor?.id === tempId);

    const getIdList = () =>
      new Set(productsList.map((el: IProduct) => el.jobPostFor?.id));

    getIdList().forEach((item) => {
      res.push([...makeArrDepth(item)]);
    });

    return res;
  };

  const deleteProductsForJobPost = (id: string | null | undefined) => {
    delJobPostProducts(id);
    delAutoFillValues(id);
  };

  const sortedProducts = sortProductsByJobPosts(products);

  return (
    <>
      {buttonTitle ? (
        <Box>
          <Button
            size="large"
            variant="outlined"
            ref={anchorRef}
            onClick={handleOpen}
            style={{
              color: '#ffffff'
            }}
          >
            {buttonTitle}
          </Button>
        </Box>
      ) : (
        <SvgIconStyle
          src={`/static/img/icons/${
            products.length ? 'header-' : 'active-'
          }basket.svg`}
          style={{ width: 24, cursor: 'pointer' }}
          onClick={handleOpen}
        />
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: DRAWER_WIDTH }
        }}
      >
        <Box>
          {sortedProducts.length ? (
            <Grid container>
              {sortedProducts.map((list) => (
                <Grid item key={list[0].jobPostFor?.id}>
                  <JopPostGroup
                    products={list}
                    openPreOrderModal={() => onOpenPreOrderModal(list)}
                    handleDeleteProductsForJobPost={() =>
                      deleteProductsForJobPost(list[0].jobPostFor?.id)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid style={{ padding: '20px' }}>
              <Title2>Basket is empty</Title2>
              <Divider sx={{ marginBottom: '40px' }} />
              <NoDataImage
                style={{ height: 240, mb: 3, width: '100%' }}
                alt="empty-content"
              />
              <Title1 style={{ margin: '20px 0' }}>
                {t('You can create new job post right now')}
              </Title1>
              <Link
                to="/dashboard/jobs/new/"
                style={{ textDecoration: 'none' }}
              >
                <ButtonPrimary style={{ padding: '10px 45px' }}>
                  {t('start')}
                </ButtonPrimary>
              </Link>
            </Grid>
          )}
        </Box>
        <ProductsModalCardContainer
          open={preOrderModalOpen}
          onClose={onClosePreOrderModal}
        >
          <VonqPreOrderForm
            products={preOrderModalData}
            ifSubmitSuccess={(id: string | undefined) => {
              deleteProductsForJobPost(id);
              delAutoFillValues(id);
              onClosePreOrderModal();
              handleClose();
            }}
          />
        </ProductsModalCardContainer>
      </Drawer>
    </>
  );
};

export default ProductsBasket;
