import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from './paths';
// guards
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // GENERAL //
    {
      exact: true,
      path: PATH_DASHBOARD.account.showcase,
      component: lazy(() => import('../views/dashboard/Dashboard'))
    },

    // COMPANY //
    {
      exact: true,
      path: PATH_DASHBOARD.company.settings,
      component: lazy(() => import('../views/dashboard/Settings'))
    },
    {
      path: PATH_DASHBOARD.culture_profile.cultureProfiles,
      component: lazy(() => import('../views/dashboard/CultureProfiles'))
    },
    {
      path: PATH_DASHBOARD.dataAnalytics.root,
      component: lazy(() => import('../views/dashboard/DataAnalytics'))
    },
    {
      path: PATH_DASHBOARD.culture_profile.cultureProfile,
      component: lazy(() => import('../views/dashboard/CultureProfile'))
    },
    {
      path: PATH_DASHBOARD.templates.root,
      component: lazy(() => import('../views/dashboard/Templates'))
    },
    // CANDIDATES //
    {
      path: PATH_DASHBOARD.jobPosts.applicantsOverview,
      component: lazy(() => import('../views/dashboard/ApplicantsOverview'))
    },
    {
      path: PATH_DASHBOARD.candidates.candidateProfile,
      component: lazy(
        () => import('../views/dashboard/candidateProfile/CandidateProfile')
      )
    },
    {
      path: PATH_DASHBOARD.candidates.matchProfile,
      component: lazy(() => import('../views/dashboard/MatchProfile'))
    },
    {
      path: PATH_DASHBOARD.candidates.matchProfileCandidates,
      component: lazy(() => import('../views/dashboard/CandidatesOverview'))
    },
    {
      path: PATH_DASHBOARD.candidates.root,
      component: lazy(() => import('../views/dashboard/Candidates'))
    },
    {
      path: PATH_DASHBOARD.calendar.root,
      component: lazy(
        () => import('../views/dashboard/candidateProfile/Calendar')
      )
    },
    {
      path: PATH_DASHBOARD.candidates.findCandidates,
      component: lazy(() => import('../views/dashboard/FindCandidates'))
    },
    {
      path: PATH_DASHBOARD.candidates.talentPoolCandidates,
      component: lazy(() => import('../views/dashboard/TalentPool'))
    },
    {
      path: PATH_DASHBOARD.messenger.root,
      component: lazy(() => import('../views/dashboard/MessengerPage'))
    },
    {
      path: PATH_DASHBOARD.candidates.chat,
      component: lazy(() => import('../views/dashboard/Messenger'))
    },

    // JOB POSTS //
    {
      path: PATH_DASHBOARD.jobPosts.bestMatches,
      component: lazy(() => import('../views/dashboard/JobPostsOverview'))
    },
    {
      path: PATH_DASHBOARD.jobPosts.jobPostsOverview,
      component: lazy(() => import('../views/dashboard/JobPostsOverview'))
    },
    {
      path: PATH_DASHBOARD.jobPosts.jobPost,
      component: lazy(() => import('../views/dashboard/JobPost'))
    },
    {
      path: PATH_DASHBOARD.jobPosts.root,
      component: lazy(() => import('../views/dashboard/Jobs'))
    },
    {
      path: PATH_DASHBOARD.jobPosts.mostViewed,
      component: lazy(() => import('../views/dashboard/JobPostsMostViewed'))
    },
    {
      path: PATH_DASHBOARD.toDoList.currentToDo,
      component: lazy(() => import('../views/dashboard/JobPoststoDoList'))
    },
    {
      path: PATH_DASHBOARD.toDoList.root,
      component: lazy(() => import('../views/dashboard/JobPoststoDoList'))
    },

    {
      path: PATH_DASHBOARD.CUSTOMERS.customerProfile,
      component: lazy(
        () =>
          import('../views/dashboard/customers/customerProfile/CustomerProfile')
      )
    },
    {
      path: PATH_DASHBOARD.CUSTOMERS.root,
      component: lazy(() => import('../views/dashboard/customers/CMS'))
    },
    {
      path: PATH_DASHBOARD.candidates.candidatesOverview,
      component: lazy(() => import('../views/dashboard/SelectedCandidates'))
    },
    {
      path: PATH_DASHBOARD.company.cmsSetting,
      component: lazy(
        () => import('../views/dashboard/companySettings/cmsSettings')
      )
    },
    {
      path: PATH_DASHBOARD.company.integrations,
      component: lazy(
        () => import('../views/dashboard/companySettings/Integrations')
      )
    },
    {
      path: PATH_DASHBOARD.user.root,
      component: lazy(
        () => import('../views/dashboard/userSettings/userSettings')
      )
    },
    {
      path: PATH_DASHBOARD.jobPosts.viewtoDoList,
      component: lazy(() => import('../views/dashboard/ViewtoDoList'))
    },

    // CAMPAIGNS //
    {
      path: PATH_DASHBOARD.jobPosts.campaignsOverview,
      component: lazy(() => import('../views/dashboard/CampaignsOverview'))
    },
    {
      path: PATH_DASHBOARD.campaigns.campaignMoreInfo,
      component: lazy(() => import('../views/dashboard/CampaignMoreInfo'))
    },
    {
      path: PATH_DASHBOARD.campaigns.startCampaign,
      component: lazy(() => import('../views/dashboard/StartCampaign'))
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;
