import { useSelector } from 'react-redux';
import { ReactNode } from 'react';
import { Redirect } from 'react-router';
// material
import { Box } from '@material-ui/core';
//
import HomeNavbar from './HomeNavbar';
import { RootState } from '../../redux/store';
import { PATH_AUTH } from '../../routes/paths';

// ----------------------------------------------------------------------

type HomeLayoutProps = {
  children: ReactNode;
};

export default function HomeLayout({ children }: HomeLayoutProps) {
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  if (!company.active) return <Redirect to={PATH_AUTH.login} />;
  return (
    <Box sx={{ height: '100%' }}>
      <HomeNavbar logo={company.logo} />
      <Box sx={{ height: '100%' }}>{children}</Box>
    </Box>
  );
}
