import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

type AuthProtectProps = {
  children: ReactNode;
};

export default function AuthProtect({ children }: AuthProtectProps) {
  const { isLoading, isAuthenticated, user } = useAuth(); // fixing bug that related to wrong redirect to Home page
  const token = new URLSearchParams(useLocation().search).get('token');
  const accessToken = localStorage.getItem('accessToken')

  if (token) {
    localStorage.setItem('newToken', token);
  } else {
    localStorage.removeItem('newToken');
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if(!token && !isAuthenticated && !user?.id && !isLoading && !accessToken){
    return <Redirect to="/" />;
  }

  if (!isAuthenticated && user?.id) {
    localStorage.removeItem('newToken');
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}
